import { Controller } from '@hotwired/stimulus'
import { Modal } from 'flowbite'

export default class extends Controller {
  static targets = ['content', 'autofocus']

  connect () {
    const options = {
      placement: 'center-center',
      onShow: () => {
        this.dispatch('show')

        if (this.hasAutofocusTarget) {
          this.#autofocus()
        }
      }
    }

    this.modal = new Modal(this.contentTarget, options)
  }

  open () {
    this.modal.show()
  }

  close () {
    this.#safeHideModal()
    // this.modal.hide()
  }

  disconnect () {
    this.#safeHideModal()
    this.#removeBackdrop()
  }

  #safeHideModal () {
    try {
      this.modal.hide()
    } catch (error) {
      // do nothing
    }
  }

  #removeBackdrop () {
    const backdropElement = document.body.querySelector('[modal-backdrop]')
    if (backdropElement) {
      backdropElement.remove()
    }
  }

  #autofocus () {
    setTimeout(() => {
      this.autofocusTarget.focus()
    }, 100)
  }
}
