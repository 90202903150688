import { TextNode } from 'lexical'
import { $createEmojiNode, EmojiNode } from './emoji_node'

const emojis = new Map([
  [':)', ['emoji happysmile', '🙂']],
  [':-)', ['emoji happysmile', '🙂']],
  [':D', ['emoji veryhappysmile', '😀']],
  [':-D', ['emoji veryhappysmile', '😀']],
  [':(', ['emoji unhappysmile', '🙁']],
  [':-(', ['emoji unhappysmile', '🙁']],
  [':]', ['emoji happysmile', '🙂']],
  [';)', ['emoji wink', '😉']],
  [';-)', ['emoji wink', '😉']],
  [':|', ['emoji neutral', '😐']],
  [':-|', ['emoji neutral', '😐']],
  [":'(", ['emoji cry', '😢']],
  ['<3', ['emoji heart', '❤']]
])

function $findAndTransformEmoji (node) {
  const text = node.getTextContent()

  for (let i = 0; i < text.length; i++) {
    const emojiData = emojis.get(text[i]) || emojis.get(text.slice(i, i + 3))

    if (emojiData !== undefined) {
      const [emojiStyle, emojiText] = emojiData
      let targetNode

      if (i === 0) {
        [targetNode] = node.splitText(i + 3)
      } else {
        [, targetNode] = node.splitText(i, i + 3)
      }

      const emojiNode = $createEmojiNode(emojiStyle, emojiText)
      targetNode.replace(emojiNode)
      return emojiNode
    }
  }

  return null
}

function $textNodeTransform (node) {
  let targetNode = node

  while (targetNode !== null) {
    if (!targetNode.isSimpleText()) {
      return
    }

    targetNode = $findAndTransformEmoji(targetNode)
  }
}

export default function registerEmojiPlugin (editor) {
  if (!editor.hasNodes([EmojiNode])) {
    throw new Error('EmojisPlugin: EmojiNode not registered on editor')
  }

  editor.registerNodeTransform(TextNode, $textNodeTransform)
}
