import { Controller } from '@hotwired/stimulus'
import { Modal } from 'flowbite'
import { stimulus } from '~/init'

export default class DownloadAppModal extends Controller {
  static values = {
    link: String
  }

  VIEWED_MOBILE_APP_INFO = 'viewedMobileAppInfo'

  connect () {
    if (!this.element) return

    this.modal = new Modal(this.element, {
      placement: 'bottom-center'
    })

    const wasViewedOnThisPage = this.#checkIsPathViewed(
      this.linkValue?.split(' ') || []
    )

    if (wasViewedOnThisPage || this.#checkIsWebview()) return

    this.open()
  }

  open () {
    this.modal.show()
  }

  close () {
    this.modal.hide()
  }

  dontShowAgain () {
    const paths = this.linkValue?.split(' ') || []

    const savedPaths = JSON.parse(
      window.localStorage.getItem(this.VIEWED_MOBILE_APP_INFO) || '[]'
    )

    window.localStorage.setItem(
      this.VIEWED_MOBILE_APP_INFO,
      JSON.stringify([
        ...savedPaths.filter((p) => !paths.includes(p)),
        ...paths
      ])
    )
  }

  #checkIsWebview () {
    const urlParams = new URLSearchParams(window.location.search)
    const webviewParam = urlParams.get('webview')

    return webviewParam === 'true'
  }

  #checkIsPathViewed (paths) {
    const viewedProposalsStr = window.localStorage.getItem(this.VIEWED_MOBILE_APP_INFO)

    if (!viewedProposalsStr) return false

    const viewedProposalsArr = JSON.parse(viewedProposalsStr)

    return paths.some((p) => viewedProposalsArr.includes(p))
  }
}

stimulus.register('download-app-modal', DownloadAppModal)
