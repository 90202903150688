// Details about this file: https://thoughtbot.com/upcase/videos/hotwire-example-stimulus-dynamic-forms
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  enable ({ target }) {
    const elements = Array.from(this.element.elements)
    const selectElements = 'selectedOptions' in target ? target.selectedOptions : [target]
    const defaultState = this.#determineState(target)

    for (const element of elements.filter(element => element.name === target.name || element.name.includes(target.name))) {
      if (element instanceof window.HTMLFieldSetElement) element.disabled = defaultState
    }

    if (target.dataset.dependentField) {
      const dependentElements = [target, document.getElementById(target.dataset.dependentField)]
      for (const element of this.#controlledElements(...dependentElements.flatMap(select => Array.from(select.selectedOptions)))) {
        if (element instanceof window.HTMLFieldSetElement) element.disabled = !defaultState
      }
    } else {
      for (const element of this.#controlledElements(...selectElements)) {
        if (element instanceof window.HTMLFieldSetElement) element.disabled = !defaultState
      }
    }
  }

  #determineState (target) {
    if (target.type === 'text') {
      return target.value.trim().length === 0
    }

    if (target.type !== 'checkbox') return true

    const isShowOnChecked = target.getAttribute('aria-on-checked') === 'show'
    return isShowOnChecked ? target.checked : !target.checked
  }

  #controlledElements (...selectedElements) {
    return selectedElements.flatMap(selectedElement => {
      const attr = selectedElement.dataset.customProperties || selectedElement.getAttribute('aria-controls')
      return this.#getElementsByTokens(attr)
    })
  }

  #getElementsByTokens (tokens) {
    const ids = (tokens ?? '').split(/\s+/)
    return ids.map(id => document.getElementById(id))
  }
}
