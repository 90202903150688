import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['item']
  static values = { open: Boolean }

  connect () {
    this.url = this.element.dataset.url
  }

  toggle () {
    this.openValue = !this.openValue
  }

  openValueChanged () {
    this.#abortPreviousRequest()
    this.openValue ? this.#loadContent() : this.#removeContent()
  }

  #abortPreviousRequest () {
    if (this.abortController) {
      this.abortController.abort()
    }
  }

  #loadContent () {
    this.#setElementState('busy', true)
    this.#fetchContent()
      .then(() => this.#setElementState('expanded', true))
      .catch(this.#handleError.bind(this))
      .finally(() => this.#setElementState('busy', false))
  }

  #removeContent () {
    this.#setElementState('expanded', false)
    this.itemTargets.forEach(item => item.remove())
  }

  #fetchContent () {
    this.abortController = new AbortController()
    return get(this.url, {
      responseKind: 'turbo-stream',
      signal: this.abortController.signal
    })
  }

  #setElementState (state, value) {
    this.element.setAttribute(`aria-${state}`, value)
  }

  #handleError (error) {
    if (!(error instanceof DOMException) || error.name !== 'AbortError') {
      console.error('Error fetching content:', error)
    }
  }
}
