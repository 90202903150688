import { Controller } from '@hotwired/stimulus'

import Informer from '@uppy/informer'
import StatusBar from '@uppy/status-bar'
import DragDrop from '@uppy/drag-drop'
import FileInput from '@uppy/file-input'

import { uppyInstance } from './uppy/core'

export default class extends Controller {
  static targets = ['fileInput', 'statusBar', 'informer', 'dragDrop']

  connect () {
    this.#setup()
    document.addEventListener('turbo:morph', this.#setup.bind(this))
  }

  disconnect () {
    if (this.uppy) {
      this.uppy.destroy()
      this.uppy = null
    }
    document.removeEventListener('turbo:morph', this.#setup.bind(this))
  }

  #setup () {
    const {
      endpoint,
      fieldName = 'image',
      autoProceed = 'true',
      allowedFileTypes,
      minNumberOfFiles = '0',
      maxNumberOfFiles = '4',
      maxFileSize = '20000000'
    } = this.element.dataset

    this.uppy = uppyInstance({
      endpoint,
      fieldName,
      autoProceed: autoProceed === 'true',
      allowedFileTypes: allowedFileTypes ? JSON.parse(allowedFileTypes) : ['image/*'],
      minNumberOfFiles: parseInt(minNumberOfFiles),
      maxNumberOfFiles: parseInt(maxNumberOfFiles),
      maxFileSize: parseInt(maxFileSize)
    }).on('upload-success', async (file, res) => {
      if (res.status === 200) {
        this.uppy.removeFile(file.id)
      }
    })

    if (this.hasDragDropTarget) {
      this.uppy.use(DragDrop, {
        target: this.element,
        height: 'auto',
        note: this.element.dataset.note,
        locale: {
          strings: {
            dropHereOr: 'Drop your file or %{browse}',
            browse: 'click to browse'
          }
        }
      })
    }

    if (this.hasFileInputTarget) {
      this.uppy.use(FileInput, {
        target: this.fileInputTarget,
        locale: {
          strings: {
            chooseFiles: 'Upload'
          }
        }
      })
    }

    if (this.hasStatusBarTarget) {
      this.uppy.use(StatusBar, {
        target: this.statusBarTarget
      })
    }

    if (this.hasInformerTarget) {
      this.uppy.use(Informer, {
        target: this.informerTarget
      })
    }

    return this.uppy
  }
}
