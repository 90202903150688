import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect () {
    this.toggleInputs()
  }

  updateInput (event) {
    const currentInput = event.currentTarget

    if (currentInput.value.trim()) {
      this.inputTargets.forEach(input => {
        if (input !== currentInput) {
          input.value = ''
          input.disabled = true
        }
      })
    } else {
      this.inputTargets.forEach(input => { input.disabled = false })
    }
  }

  toggleInputs () {
    if (this.inputTargets.some(input => input.value.trim())) {
      this.inputTargets.forEach(input => {
        if (!input.value.trim()) input.disabled = true
      })
    }
  }
}
