import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['quantity', 'unitCost', 'tax', 'amount', 'subtotal', 'totalTax', 'total', 'currency']
  static values = { currency: { type: String, default: 'USD' } }

  connect() {
    this.updateTotals()
  }

  currencyValueChanged() {
    this.currencyTargets.forEach(currencyElement => {
      currencyElement.textContent = this.currencyValue
    })

    this.updateTotals()
  }

  cloneRow(event) {
    const targetBody = this.element.querySelector('#costBreakdownTbody')
    const currentRow = event.currentTarget.closest('tr')
    const newRow = currentRow.cloneNode(true)

    targetBody.appendChild(newRow)
    this.updateTotals()
  }

  removeRow(event) {
    const currentRow = event.currentTarget.closest('tr')

    currentRow.remove()
    this.updateTotals()
  }

  updateCurrency(event) {
    this.currencyValue = event.detail.currency
  }

  updateTotals() {
    const totals = this.calculateTotals()
    this.updateDisplay(totals)
  }

  calculateTotals() {
    let subtotal = 0
    let totalTax = 0
    let total = 0

    this.amountTargets.forEach((amountTarget, index) => {
      const quantity = this.getValue(this.quantityTargets[index])
      const unitCost = this.getValue(this.unitCostTargets[index])
      const tax = this.getValue(this.taxTargets[index])

      const { itemSubtotal, itemTax, itemTotal } = this.calculateItemTotals(quantity, unitCost, tax, index)

      amountTarget.value = itemTotal.toFixed(2)

      subtotal += itemSubtotal
      totalTax += itemTax
      total += itemTotal
    })

    return { subtotal, totalTax, total }
  }

  calculateItemTotals(quantity, unitCost, tax, index) {
    const itemSubtotal = quantity * unitCost
    const isFixedTax = this.taxTargets[index].dataset.taxFixed === 'true'
    const itemTax = isFixedTax ? tax : itemSubtotal * (tax / 100)
    const itemTotal = this.isTaxInclusive(index) ? itemSubtotal : itemSubtotal + itemTax

    return { itemSubtotal, itemTax, itemTotal }
  }

  isTaxInclusive(index) {
    return this.taxTargets[index].dataset.taxType === 'inclusive'
  }

  isTaxExclusive(index) {
    return !this.isTaxInclusive(index)
  }

  getValue(target) {
    return parseFloat(target.value) || 0
  }

  updateDisplay({ subtotal, totalTax, total }) {
    this.subtotalTarget.textContent = `${subtotal.toFixed(2)} ${this.currencyValue}`
    this.totalTaxTarget.textContent = `${totalTax.toFixed(2)} ${this.currencyValue}`
    this.totalTarget.textContent = `${total.toFixed(2)} ${this.currencyValue}`
  }
}
