import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['searchForm', 'searchBtn', 'input', 'clearBtn']

  connect () {
    this.checkClearBtn()
  }

  clickOutside (e) {
    this.close()
  }

  open () {
    this.searchBtnTarget.classList.remove('search-closing')
    this.searchBtnTarget.classList.add('search-opening')
    this.searchFormTarget.classList.remove('hidden')

    this.inputTarget.focus()
    useClickOutside(this, { element: this.searchBtnTarget })

    setTimeout(() => {
      this.searchBtnTarget.classList.add('search-active')
      this.searchBtnTarget.classList.remove('search-opening')
    }, 150)
  }

  close () {
    if (this.inputTarget.value.trim() === '') {
      this.searchBtnTarget.classList.remove('search-opening')
      this.searchBtnTarget.classList.add('search-closing')

      setTimeout(() => {
        this.searchFormTarget.classList.add('hidden')
        this.searchBtnTarget.classList.remove('search-closing')
        this.searchBtnTarget.classList.remove('search-active')
      }, 150)
    }
  }

  clearInput (e) {
    e.preventDefault()
    e.stopPropagation()
    this.inputTarget.value = ''
    this.checkClearBtn()
    this.close()
  }

  checkClearBtn () {
    if (this.inputTarget.value.trim() === '') {
      this.clearBtnTarget.classList.add('opacity-0', 'invisible')
      this.clearBtnTarget.classList.remove('opacity-100')
    } else {
      this.clearBtnTarget.classList.remove('opacity-0', 'invisible')
      this.clearBtnTarget.classList.add('opacity-100')
    }
  }
}
