import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['expensePaymentIndex']

  paymentAdd () {
    this.recalculateIndexes()
  }

  recalculateIndexes () {
    let visibleIndex = 0

    this.expensePaymentIndexTargets.forEach((target) => {
      if (target.style.display !== 'none') {
        target.textContent = `#${visibleIndex + 1}`
        visibleIndex++
      }
    })
  }
}
