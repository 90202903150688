import { Controller } from '@hotwired/stimulus'
import {
  createEditor,
  $getRoot, $getSelection, $isRangeSelection, $insertNodes,
  COMMAND_PRIORITY_HIGH, KEY_ENTER_COMMAND, $createParagraphNode
} from 'lexical'
import { $isListItemNode, ListNode, ListItemNode } from '@lexical/list'
import { registerDragonSupport } from '@lexical/dragon'
import { createEmptyHistoryState, registerHistory } from '@lexical/history'
import { registerRichText } from '@lexical/rich-text'
import { LinkNode } from '@lexical/link'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { mergeRegister } from '@lexical/utils'
import registerToolbarActions from './lexical/toolbar_plugin'
import registerLinkActions from './lexical/link_plugin'
import registerEmojiPlugin from './lexical/emoji_plugin'
import { EmojiNode } from './lexical/emoji_node'
import registerEmojiPickerActions from './lexical/emoji_picker_plugin'

export default class LexicalController extends Controller {
  static targets = [
    'editor', 'input', 'undoBtn', 'redoBtn', 'boldBtn', 'italicBtn', 'underlineBtn',
    'ulBtn', 'olBtn', 'linkBtn', 'linkEditor', 'linkInput', 'emojiPicker'
  ]

  connect () {
    this.#initializeEditor()
    this.#loadInitialState()
    this.#registerPlugins()
    this.#registerUpdateListener()
  }

  // Toolbar Actions
  undo () { this.toolbarActions.undo() }
  redo () { this.toolbarActions.redo() }
  bold () { this.toolbarActions.bold() }
  italic () { this.toolbarActions.italic() }
  underline () { this.toolbarActions.underline() }
  alignLeft () { this.toolbarActions.alignLeft() }
  alignCenter () { this.toolbarActions.alignCenter() }
  alignRight () { this.toolbarActions.alignRight() }
  alignJustify () { this.toolbarActions.alignJustify() }
  listBullet () { this.toolbarActions.listBullet() }
  listOrdered () { this.toolbarActions.listOrdered() }

  // Link Actions
  applyLink (e) { this.linkActions.applyLink(e) }
  unlink () { this.linkActions.unlink() }
  showLinkEditor () { this.linkActions.showLinkEditor() }
  closeLinkEditor () { this.linkActions.closeLinkEditor() }
  clickOutside () { this.linkActions.clickOutside() }

  // Emoji Picker Actions
  applyEmoji (e) {
    this.emojiPickerActions.applyEmoji(e)
  }

  // Private Methods
  #initializeEditor () {
    this.editor = createEditor({ ...this.#getEditorConfig() })
    this.editor.setRootElement(this.editorTarget)
  }

  #getEditorConfig () {
    return {
      namespace: 'Lexical',
      nodes: [LinkNode, ListNode, ListItemNode, EmojiNode],
      theme: this.#getEditorTheme()
    }
  }

  #getEditorTheme () {
    return {
      placeholder: 'lexical__placeholder',
      paragraph: 'lexical__paragraph',
      list: {
        nested: { listitem: 'lexical__nested-listitem' },
        ol: 'lexical__list-ol',
        ul: 'lexical__list-ul',
        listitem: 'lexical__listitem'
      },
      image: 'lexical__image',
      link: 'lexical__link',
      text: {
        bold: 'lexical__text-bold',
        italic: 'lexical__text-italic',
        hashtag: 'lexical__text-hashtag',
        underline: 'lexical__text-underline',
        strikethrough: 'lexical__text-strikethrough',
        underlineStrikethrough: 'lexical__text-underlineStrikethrough'
      }
    }
  }

  #loadInitialState () {
    this.editor.update(() => {
      const parser = new window.DOMParser()
      const dom = parser.parseFromString(this.inputTarget.value, 'text/html')
      const nodes = $generateNodesFromDOM(this.editor, dom)
      $getRoot().select()
      $insertNodes(nodes)
    })
  }

  #registerPlugins () {
    mergeRegister(
      registerRichText(this.editor),
      registerDragonSupport(this.editor),
      registerHistory(this.editor, createEmptyHistoryState(), 300)
    )
    this.toolbarActions = registerToolbarActions(this)
    this.linkActions = registerLinkActions(this)
    this.emojiPickerActions = registerEmojiPickerActions(this)
    this.emojiActions = registerEmojiPlugin(this.editor)
  }

  #registerUpdateListener () {
    this.removeUpdateListener = this.editor.registerUpdateListener(({ editorState }) => {
      this.toolbarActions.updateToolbarState(editorState)
      editorState.read(() => {
        const htmlContent = $generateHtmlFromNodes(this.editor, null)
        if (htmlContent === '<p class="lexical__paragraph"><br></p>') {
          this.inputTarget.value = ''
        } else {
          this.inputTarget.value = htmlContent
        }
        this.selection = $getSelection()
      })
    })

    this.#registerCommands()
  }

  #registerCommands () {
    this.editor.registerCommand(
      KEY_ENTER_COMMAND,
      this.#handleEnterCommand.bind(this),
      COMMAND_PRIORITY_HIGH
    )

    this.toolbarActions.registerCommands()
    this.linkActions.registerCommands()
  }

  #handleEnterCommand (e) {
    if ($isRangeSelection(this.selection)) {
      const node = this.selection.anchor.getNode()
      if ($isListItemNode(node)) {
        e.preventDefault()
        this.editor.update(() => {
          const paragraphNode = $createParagraphNode()
          node.insertAfter(paragraphNode, node)
          node.remove()
          paragraphNode.select()
        })
        return true
      }
    }
    return false
  }
}
