import {
  $getSelection,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  UNDO_COMMAND,
  REDO_COMMAND,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_LOW
} from 'lexical'

import { insertList, removeList } from '@lexical/list'
import { $isLinkNode } from '@lexical/link'

export default function registerToolbarActions (controller) {
  const editor = controller.editor

  function isListType (node, type) {
    if (!node) return false
    if (node.getType() === 'list' && node.getListType() === type) return true
    return isListType(node.getParent(), type)
  }

  return {
    undo () { editor.dispatchCommand(UNDO_COMMAND) },
    redo () { editor.dispatchCommand(REDO_COMMAND) },
    bold () { editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold') },
    italic () { editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic') },
    underline () { editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline') },
    alignLeft () { editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left') },
    alignCenter () { editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center') },
    alignRight () { editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right') },
    alignJustify () { editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify') },
    listBullet () {
      editor.update(() => {
        const selection = controller.selection
        const node = selection.anchor.getNode()
        if (isListType(node, 'bullet')) {
          removeList(editor)
        } else {
          insertList(editor, 'bullet')
        }
      })
    },
    listOrdered () {
      editor.update(() => {
        const selection = controller.selection
        const node = selection.anchor.getNode()
        if (isListType(node, 'number')) {
          removeList(editor)
        } else {
          insertList(editor, 'number')
        }
      })
    },
    registerCommands () {
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          controller.undoBtnTarget.disabled = !payload
          return false
        },
        COMMAND_PRIORITY_LOW
      )

      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          controller.redoBtnTarget.disabled = !payload
          return false
        },
        COMMAND_PRIORITY_LOW
      )
    },
    updateToolbarState (editorState) {
      editorState.read(() => {
        const selection = $getSelection()
        const node = selection.anchor.getNode()
        const parent = node.getParent()

        controller.boldBtnTarget.classList.toggle('lexical__btn-active', selection.hasFormat('bold'))
        controller.italicBtnTarget.classList.toggle('lexical__btn-active', selection.hasFormat('italic'))
        controller.underlineBtnTarget.classList.toggle('lexical__btn-active', selection.hasFormat('underline'))
        controller.ulBtnTarget.classList.toggle('lexical__btn-active', isListType(node, 'bullet'))
        controller.olBtnTarget.classList.toggle('lexical__btn-active', isListType(node, 'number'))

        const isLinkNode = $isLinkNode(parent)
        controller.linkBtnTarget.classList.toggle('lexical__btn-active', isLinkNode)
        controller.linkInputTarget.value = isLinkNode ? parent.getURL() : ''
      })
    }
  }
}
