import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  toggle (event) {
    const checkbox = event.target
    const isChecked = checkbox.checked
    const checkboxValue = checkbox.value

    this.inputTargets.forEach(inputTarget => this.#updateFieldValues(inputTarget, isChecked, checkboxValue))
  }

  #updateFieldValues (field, isChecked, checkboxValue) {
    let valuesArray = this.#getValuesArrayFromField(field)

    if (isChecked) {
      valuesArray.push(checkboxValue)
    } else {
      valuesArray = valuesArray.filter(value => value !== checkboxValue)
    }

    this.#updateFieldValue(field, valuesArray)
  }

  #getValuesArrayFromField (field) {
    return field.value.length > 0 ? field.value.split('-') : []
  }

  #updateFieldValue (field, valuesArray) {
    const uniqueValues = [...new Set(valuesArray)]
    field.value = uniqueValues.join('-')
  }
}
