import { Controller } from '@hotwired/stimulus'
import { Modal } from 'flowbite'
import { stimulus } from '~/init'

export default class ModalController extends Controller {
  connect () {
    this.initializeModal()
    this.modal.show()
  }

  initializeModal() {
    const options = this.getModalOptions()
    const instanceOptions = this.getInstanceOptions()
    this.modal = new Modal(this.element, options, instanceOptions)
  }

  getModalOptions() {
    return {
      placement: 'center-center',
      // backdrop: 'static',
      closable: this.element.dataset.closable === 'true',
      onHide: this.handleModalHide.bind(this)
    }
  }

  getInstanceOptions() {
    return {
      id: 'modalContainer',
      override: false
    }
  }

  handleModalHide() {
    ModalController.modalTurboFrame.src = null
    this.element.src = null
    this.element.remove()
  }

  open () {
    this.modal.show()
  }

  close () {
    this.modal.hide()
  }

  disconnect () {
    this.safeHideModal()
    this.removeBackdrop()
  }

  safeHideModal() {
    try {
      this.modal.hide()
    } catch (error) {
      // do nothing
    }
  }

  removeBackdrop() {
    const backdropElement = document.body.querySelector('[modal-backdrop]')
    if (backdropElement) {
      backdropElement.remove()
    }
  }

  static get modalTurboFrame () {
    return document.querySelector('turbo-frame[id=\'modal\']')
  }
}

stimulus.register('modal', ModalController)
