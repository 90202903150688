import { registerControllers } from 'stimulus-vite-helpers'
import { stimulus } from '~/init'
import AutoSubmit from '@stimulus-components/auto-submit'
import TextareaAutogrow from 'stimulus-textarea-autogrow'

stimulus.register('auto-submit', AutoSubmit)
stimulus.register('textarea-autogrow', TextareaAutogrow)

const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(stimulus, controllers)
