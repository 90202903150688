export function getSelectionCoordinates () {
  const selection = window.getSelection()
  if (!selection.rangeCount) return null
  const range = selection.getRangeAt(0)
  const rect = range.getBoundingClientRect()

  return {
    top: rect.top + document.documentElement.scrollTop,
    left: rect.left + document.documentElement.scrollLeft,
    width: rect.width,
    height: rect.height
  }
}
