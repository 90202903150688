import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'list', 'empty']

  filter (e) {
    const lowerCaseFilterTerm = e.target.value.toLowerCase()

    const result = this.listTargets.filter((el) => {
      const filterableKey = el.getAttribute('data-filter-key')

      el.classList.toggle('hidden', !filterableKey.includes(lowerCaseFilterTerm))

      if (filterableKey.includes(lowerCaseFilterTerm)) {
        return el
      }

      return null
    })

    this.emptyTarget.classList.toggle('hidden', result.length > 0)
  }
}
