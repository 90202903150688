import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.attribute =
      this.element.getAttribute('data-toggler-attr') || 'data-toggler-show'
  }

  open (e) {
    if (!this.matchesId(e)) return

    this.element.setAttribute(this.attribute, '')
  }

  close (e) {
    if (!this.matchesId(e)) return

    this.element.removeAttribute(this.attribute)
  }

  toggle (e) {
    if (this.element.hasAttribute(this.attribute)) {
      this.close(e)
    } else {
      this.open(e)
    }
  }

  matchesId (e) {
    const id = this.element.getAttribute('id')
    const paramId = e.params.id

    return id === paramId
  }
}
