import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'input',
    'eyeButton',
    'popover',
    'length',
    'lowercaseLetter',
    'uppercaseLetter',
    'number',
    'identical'
  ]

  connect () {
    this.#validatePassword()

    if (this.hasPopoverTarget) {
      this.showPopover()
    }
  }

  input (event) {
    this.password = event.target.value
  }

  showPopover () {
    this.popoverTarget.classList.add('password_requirements-active')
  }

  hidePopover () {
    this.popoverTarget.classList.remove('password_requirements-active')
  }

  toggle () {
    this.inputTarget.type = this.inputTarget.type === 'password' ? 'text' : 'password'
    this.eyeButtonTarget.classList.toggle('slashed')
  }

  set password (value) {
    this.data.set('password', value)
    this.#validatePassword()
  }

  get password () {
    if (!this.data.has('password')) {
      this.password = ''
    }

    return this.data.get('password')
  }

  get #popoverOptions () {
    return {
      offset: '40',
      triggerType: 'none',
      placement: 'right-start'
    }
  }

  #validatePassword () {
    // Validate lowercase letters
    if (this.hasLowercaseLetterTarget) {
      const lowerCaseLetters = /[a-z]/g
      if (this.password.match(lowerCaseLetters)) {
        this.lowercaseLetterTarget.classList.add('valid')
      } else {
        this.lowercaseLetterTarget.classList.remove('valid')
      }
    }

    // Validate capital letters
    if (this.hasUppercaseLetterTarget) {
      const upperCaseLetters = /[A-Z]/g
      if (this.password.match(upperCaseLetters)) {
        this.uppercaseLetterTarget.classList.add('valid')
      } else {
        this.uppercaseLetterTarget.classList.remove('valid')
      }
    }

    // Validate numbers
    if (this.hasNumberTarget) {
      const numbers = /[0-9]/g
      if (this.password.match(numbers)) {
        this.numberTarget.classList.add('valid')
      } else {
        this.numberTarget.classList.remove('valid')
      }
    }

    // Validate length
    if (this.hasLengthTarget) {
      if (this.password.length >= 8) {
        this.lengthTarget.classList.add('valid')
      } else {
        this.lengthTarget.classList.remove('valid')
      }
    }

    // Validate special symbols (.!%$~^&-_*)
    if (this.hasIdenticalTarget) {
      const identical = /[.!%$~^&\-_*]/

      if (this.password.match(identical)) {
        this.identicalTarget.classList.add('valid')
      } else {
        this.identicalTarget.classList.remove('valid')
      }
    }
  }
}
