import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'btn']

  connect () {
    this.check()
  }

  check () {
    if (this.inputTarget.value !== '') {
      this.#showBtn()
    } else {
      this.#hideBtn()
    }
  }

  clear () {
    this.inputTarget.value = ''
    const event = new Event('change', { bubbles: true, cancelable: true })
    this.inputTarget.dispatchEvent(event)

    this.#hideBtn()
  }

  #showBtn () {
    this.btnTarget.classList.remove('hidden')
  }

  #hideBtn () {
    this.btnTarget.classList.add('hidden')
  }
}
