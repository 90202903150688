import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'button', 'preview', 'previewContainer', 'removeCheckbox']

  selectImage () {
    if (this.hasRemoveCheckboxTarget) {
      this.removeCheckboxTarget.checked = false
    }

    this.inputTarget.click()
  }

  removeImage () {
    this.#previewHide()

    if (this.hasRemoveCheckboxTarget) {
      this.removeCheckboxTarget.checked = true
    }
  }

  createObjectURL () {
    if (this.inputTarget.files && this.inputTarget.files[0]) {
      const imageFile = this.inputTarget.files[0]
      if (imageFile) {
        this.previewTarget.src = URL.createObjectURL(imageFile)
        this.#previewShow()
      }
    }
  }

  #previewShow () {
    this.previewContainerTarget.classList.remove('hidden')
    this.buttonTarget.classList.add('hidden')
  }

  #previewHide () {
    this.previewContainerTarget.classList.add('hidden')
    this.buttonTarget.classList.remove('hidden')
  }
}
