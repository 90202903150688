import 'emoji-picker-element'
import { $createTextNode } from 'lexical'

export default function registerEmojiPickerActions (controller) {
  return {
    applyEmoji (e) {
      const { detail } = e

      controller.editor.update(() => {
        if (controller.selection) {
          controller.selection.insertNodes([$createTextNode(detail.unicode)])
        }
      })
    }
  }
}
