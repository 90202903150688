// controllers/accordion_controller.js

import { Controller } from '@hotwired/stimulus'

const STORAGE_KEY = 'task_sets_reveal_state_map'

export default class AccordionController extends Controller {
  static targets = ['item', 'icon']

  connect() {
    this.key = this.element.dataset.controllerId || this.element.id || 'default'
    this.isOpenedValue = true
    this.itemHiddenClass = 'hidden'
    this.itemIconClass = 'rotate-180'
    this.restoreState()
  }

  restoreState() {
    const stateMap = this.#loadStateMap()
    const state = stateMap[this.key]
    if (!!state) {
      this.isOpenedValue = state === 'shown'
    }
    this.#toggle();
  }

  toggle() {
    this.isOpenedValue = !this.isOpenedValue
    this.#toggle()

    this.#saveState()
  }

  #toggle() {
    if (this.isOpenedValue) {
      this.#show()
    } else {
      this.#hide()
    }
  }

  #show() {
    this.itemTarget.classList.remove(this.itemHiddenClass)
    this.iconTarget.classList.remove(this.itemIconClass)
  }

  #hide() {
    this.itemTarget.classList.add(this.itemHiddenClass)
    this.iconTarget.classList.add(this.itemIconClass)
  }

  #loadStateMap() {
    const storedMap = sessionStorage.getItem(STORAGE_KEY)
    return storedMap ? JSON.parse(storedMap) : {}
  }

  #saveState() {
    const stateMap = this.#loadStateMap()
    const state = this.isOpenedValue ? 'shown' : 'hidden'
    stateMap[this.key] = state
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(stateMap))
  }
}
