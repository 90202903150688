import { sanitizeUrl, isValidUrl } from '~/utils/url'
import { CLICK_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical'
import { $toggleLink } from '@lexical/link'
import { useClickOutside } from 'stimulus-use'
import { getSelectionCoordinates } from '~/utils/window'

export default function registerLinkActions (controller) {
  return {
    applyLink (e) {
      const inputValue = controller.linkInputTarget.value.trim()
      const url = sanitizeUrl(inputValue)

      if (url === '') {
        controller.unlink()
        return
      }
      if (!isValidUrl(url)) {
        controller.linkInputTarget.classList.add('form__input-errored')
        return
      }

      controller.linkInputTarget.classList.remove('form__input-errored')
      controller.editor.update(() => {
        $toggleLink(url)
      })
      controller.closeLinkEditor()
    },

    unlink () {
      controller.editor.update(() => { $toggleLink(null) })
      controller.closeLinkEditor()
    },

    showLinkEditor (options = { focus: true }) {
      const { focus } = options
      const linkEditorElem = controller.linkEditorTarget
      const position = getSelectionCoordinates()

      linkEditorElem.style.top = `${position.top + 24}px`
      linkEditorElem.style.left = `${position.left}px`
      linkEditorElem.classList.remove('hidden')

      if (focus) {
        controller.linkInputTarget.focus()
      }

      useClickOutside(controller, { element: controller.linkEditorTarget })
    },

    closeLinkEditor () {
      controller.linkEditorTarget.classList.add('hidden')
    },

    clickOutside () { controller.closeLinkEditor() },

    registerCommands () {
      controller.editor.registerCommand(
        CLICK_COMMAND,
        (_payload, _newEditor) => {
          const currentElement = _payload.target
          const parentElement = currentElement.parentElement

          if (parentElement.tagName === 'A') {
            this.showLinkEditor({ focus: false })
          }

          return false
        },
        COMMAND_PRIORITY_LOW
      )
    }
  }
}
