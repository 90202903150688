import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

export function uppyInstance (config) {
  const {
    endpoint,
    fieldName = 'image',
    autoProceed = true,
    allowedFileTypes = ['image/*'],
    minNumberOfFiles = 0,
    maxNumberOfFiles = 4,
    maxFileSize = 20000000
  } = config

  const uppyConfig = {
    autoProceed,
    restrictions: {
      allowedFileTypes,
      minNumberOfFiles,
      maxNumberOfFiles,
      maxFileSize
    }
  }

  const xhrUploadConfig = {
    endpoint,
    fieldName,
    formData: true,
    responseType: 'text',
    headers: {
      Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
    },
    onAfterResponse (response) {
      window.Turbo.renderStreamMessage(response.responseText)
    },
    getResponseData (response) {
      return response
    }
  }

  const uppy = new Uppy(uppyConfig)

  uppy.use(XHRUpload, xhrUploadConfig)

  return uppy
}
