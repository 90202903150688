import Clipboard from '@stimulus-components/clipboard'

export default class extends Clipboard {
  copied () {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    if (this.successContentValue) {
      this.buttonTarget.innerHTML = this.successContentValue

      this.timeout = setTimeout(() => {
        this.buttonTarget.innerHTML = this.originalContent
      }, this.successDurationValue)
    } else {
      this.buttonTarget.innerHTML = this.originalContent
    }
  }
}
