import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon', 'item']
  static classes = ['hidden']

  connect () {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
    this.iconClass = (this.hasIconTarget && this.iconTarget.className) ? this.iconTarget.className : 'rotate-180'
  }

  toggle () {
    this.itemTargets.forEach((item) => {
      item.classList.toggle(this.class)
    })
    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle(this.iconClass)
    }
  }

  show () {
    this.itemTargets.forEach((item) => {
      item.classList.remove(this.class)
    })
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove(this.iconClass)
    }
  }

  hide () {
    this.itemTargets.forEach((item) => {
      item.classList.add(this.class)
    })
    if (this.hasIconTarget) {
      this.iconTarget.classList.add(this.iconClass)
    }
  }
}
