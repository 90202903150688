const SUPPORTED_URL_PROTOCOLS = ['http://', 'https://', 'mailto:']

const urlRegExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/

export function sanitizeUrl (url) {
  try {
    const parsedUrl = new URL(url)
    // eslint-disable-next-line no-script-url
    if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
      return 'about:blank'
    }
  } catch {
    return url
  }
  return url
}

export function isValidUrl (url) {
  return SUPPORTED_URL_PROTOCOLS.some(protocol => url.startsWith(protocol)) && urlRegExp.test(url)
}
